import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"

const MentionsLegalesPage = () => (
  <Layout>
    <Seo title="Mentions Légales" breadcrumb={[
      { url: "mentions-legales", name: "Mentions Légales"},
    ]} />
    <Breadcrumb paths={[{url: null, name:"Mentions Légales"}]} />

    <h1 className="h1">Mentions légales</h1>
    <p className="text md:w-3/4">Le site yvanblin.com est hébergé par la SAS OVH, basée à Roubaix (France).</p>

    <p className="text md:w-3/4">Toute reproduction ou modification (totale ou partielle) des éléments graphiques ou du contenu sans autorisation préalable et écrite est interdite.
    Par ailleurs, nous vous informons que toute tentative de piratage ou de détournement du site sera enregistrée et donnera lieu à des poursuites judiciaires.
    Toutes les images présentes sur le site, le sont à titre d'illustrations et ont été autorisés par l'aimable accord des ayants droits.</p>

    <p className="text md:w-3/4">Conformément aux dispositions contenues dans la loi n° 78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés, vous disposez d’un droit d’accès, de rectification, de modification et de suppression concernant les données qui vous concernent.
    Vous pouvez exercer ce droit en envoyant un courriel via la page contact ou un courrier postal à l’adresse suivante, en indiquant si possible le contexte dans lequel cette collecte de données personnelles a été effectuée afin que nous puissions retrouver plus facilement les informations vous concernant.
    </p>

    <p className="text card inline-block">
      <span className="font-bold">Ecole de conduite Yvan Blin</span><br />
      3 bd Louis Giroux<br />
      35500 Vitré
    </p>

    <p className="leading-6 mb-6 italic text-sm">
      Agrément: E0203504450<br />
      Siret: 383 171 808 00017<br />
      N° Identification TVA : FR 58 383 171 808
    </p>
  </Layout>
)

export default MentionsLegalesPage
